import React from "react";

const Title = ({ title, className }) => {
  return (
    <div className="relative ml-2 mb-2 ">
      <div className={`text-start text-2xl mb-3 mr-3 ${className}  font-bold`}>
        {title}
      </div>
      <span className="w-2 h-2 rounded-full bg-Green absolute  top-1/2 -translate-y-1/2 right-0"></span>
    </div>
  );
};

export default Title;
