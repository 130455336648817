import {
  AddCostCenters,
  AddCostCentersAll,
  AddDepartmentManagers,
  AddSections,
  AddWorkers,
  AdministrativeCost,
  CenterRepo,
  CenterSectionRepo,
  CenterSectionReports,
  ChangePassword,
  CostCenters,
  CostCentersAll,
  CostCentersWorkers,
  DepartmentManagers,
  Login,
  Report,
  Reportscenters,
  SecReport,
  Sections,
  SectionsWorkers,
  WorkerCenterReport,
  WorkerRepots,
  Workers,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Logo from "./images/euroglass.png";
import { checkToken, CheckToken, RequireAuth, useFETCH } from "./APIs/useMyAPI";
import { Container } from "./Grid-system";
import Calendarsec from "./components/Calendar/Calendarsec";
import Admin from "./pages/Admin/Admin";
import CenterWorkers from "./pages/CenterWorkers/CenterWorkers";
import WorkerReport from "./pages/WorkerReport/WorkerReport";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

const App = () => {
  const { data } = useFETCH(
    localStorage.getItem("token")
      ? localStorage.getItem("role")
        ? "s-admin/check/auth"
        : "admin/check/auth"
      : ""
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("login")) {
      if (data?.data?.data?.success === 200) return null;
      else navigate("/login");
    }
  }, [data]);

  return (
    <>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route element={<RequireAuth />}>
                <Route path="admin">
                  <Route index element={<Admin />} />
                  <Route path="workers/:workerId" element={<WorkerReport />} />
                  <Route path="center/:centerId">
                    <Route index element={<CenterWorkers />} />
                    <Route
                      path="workers/:workerId"
                      element={<WorkerReport />}
                    />
                  </Route>
                </Route>
                <Route
                  index
                  element={
                    <h1 className="grid place-content-center h-[60vh]">
                      <img src={Logo} alt="" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="sections">
                  <Route index element={<Sections />} />
                  <Route path=":id" element={<AddSections />} />
                  <Route path="report/:id" element={<SecReport />} />
                  {/* <Route path="report/:id" element={<SecReport />} /> */}
                  <Route
                    path="report/:id/:date/:cost_center_id"
                    element={<Report />}
                  />
                  <Route
                    path="report/:id/:date/:cost_center_id/:section_id"
                    element={<CenterSectionRepo />}
                  />
                  <Route path="worker/:id" element={<SectionsWorkers />} />
                  <Route path="cost-centers/:id" element={<CostCenters />} />
                  <Route
                    path="cost-centers/:id/report/:idw"
                    element={<CenterSectionReports />}
                  />
                  <Route
                    path="cost-centers/:id/:idu"
                    element={<AddCostCenters />}
                  />
                </Route>
                <Route path="workers">
                  <Route index element={<Workers />} />
                  <Route path=":id" element={<AddWorkers />} />
                  <Route path="reports/:id" element={<WorkerRepots />} />
                </Route>
                <Route path="department-managers">
                  <Route index element={<DepartmentManagers />} />
                  <Route path=":id" element={<AddDepartmentManagers />} />
                </Route>
                <Route path="cost-centers">
                  <Route index element={<CostCentersAll />} />
                  <Route path=":id" element={<AddCostCentersAll />} />
                  <Route path="workers/:id" element={<CostCentersWorkers />} />
                  <Route
                    path=":id/workers/:idw"
                    element={<WorkerCenterReport />}
                  />
                  <Route path="reports/:id" element={<Reportscenters />} />
                  <Route path="report/:id/:date" element={<CenterRepo />} />
                </Route>

                <Route
                  path="administrative-cost"
                  element={<AdministrativeCost />}
                />
                <Route path="Change-Password" element={<ChangePassword />} />

                <Route path="/calendar/:type/:id" element={<Calendarsec />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
