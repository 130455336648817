import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { baseUrl, useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import axios from "axios";

const fetchSectionData = async (id) => {
  return axios
    .get(baseUrl + `admin/sections/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      return response;
    });
};

const AddSections = () => {
  const { id } = useParams();
  const [data, setData] = useState(null); // Use state to store fetched data

  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
  } = usePOST();
  useEffect(() => {
    if (id !== "add") {
      const fetchData = async () => {
        const response = await fetchSectionData(id);
        setData(response); // Store fetched data in state
      };
      fetchData();
    }
  }, [id]); // The effect runs when 'id' changes
  let dataEdite = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataEdite?.name,
        administrative_hours_percentage:
          dataEdite?.administrative_hours_percentage,
      });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id !== "add" ? `admin/sections/${id}` : "admin/sections");
  };
  return (
    <div>
      <Container>
        {loading ? <Loading /> : ""}
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <input
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleChangeInput}
                placeholder="اسم القسم"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <input
                type="number"
                name="administrative_hours_percentage"
                value={formData?.administrative_hours_percentage}
                onChange={handleChangeInput}
                placeholder="التكلفة الأدارية"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl  text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddSections;
