import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
const AddDepartmentManagers = () => {
  const { id } = useParams();
  const { data: sectionData } = useFETCH("admin/sections?paginate=1");
  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
  } = usePOST();
  const { data } = useFETCH(`admin/sections/admins/${id}`);
  let dataEdite = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataEdite?.name,
        username: dataEdite?.username,
        section_id: dataEdite?.section_id,
      });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id !== "add" ? `admin/sections/admins/${id}` : "admin/sections/admins"
    );
  };
  return (
    <div>
      {loading ? <Loading /> : ""}
      <Container>
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={7}>
              <input
                onChange={handleChangeInput}
                type="text"
                name="name"
                value={formData?.name}
                placeholder="اسم المدير"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <input
                onChange={handleChangeInput}
                type="text"
                name="username"
                value={formData?.username}
                placeholder=" اسم المستخدم لتسحيل الدخول"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <select
                name="section_id"
                value={formData?.section_id}
                onChange={handleChangeInput}
                defaultValue={"choose"}
                className="w-full outline-none border border-black py-3 rounded-xl"
              >
                <option className="hidden" value="choose">
                  اختر...
                </option>
                {sectionData?.data.data.map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={7}>
              <input
                onChange={handleChangeInput}
                type="password"
                name="password"
                placeholder="كلمة السر"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={7}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl  text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddDepartmentManagers;
