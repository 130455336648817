import Logo from "../../images/euroglass.png";
import { Loading } from "../../components";
import { BiHide, BiShow } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useLOGIN } from "../../APIs/useMyAPI";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [biShow, setBiShow] = useState(false);
  const [isSectionAdmin, setIsSectionAdmin] = useState(
    localStorage.getItem("role") ? true : false
  );

  const {
    handleSubmit,
    error,
    loading,
    handleChange,
    successfulLogin,
    setFormData,
    formData,
  } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(isSectionAdmin ? "s-admin/login" : "admin/login");
  };

  const handleChangeRole = (e) => {
    setIsSectionAdmin(e.target.checked);
  };

  useEffect(() => {
    if (isSectionAdmin) {
      localStorage.setItem("role", "section_admin");
      setFormData({
        username: formData?.email,
        password: formData?.password,
      });
    } else {
      localStorage.removeItem("role");
      setFormData({
        email: formData?.username,
        password: formData?.password,
      });
    }
  }, [isSectionAdmin]);

  useEffect(() => {
    if (successfulLogin) {
      navigate("/");
    }
  }, [successfulLogin]);

  return (
    <div
      style={{ direction: "ltr" }}
      className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center"
    >
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Brown p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[300px] mx-auto" />
        </div>
        <div className="flex w-full mx-3" dir="rtl">
          <input
            type="text"
            value={isSectionAdmin ? formData?.username : formData?.email}
            name={isSectionAdmin ? "username" : "email"}
            onChange={handleChange}
            placeholder={isSectionAdmin ? "اسم المستخدم" : "الايميل"}
            className="placeholder: block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div
          className="flex justify-between items-center w-full mx-3 relative"
          dir="rtl"
        >
          <input
            type={biShow ? "text" : "password"}
            onChange={handleChange}
            name="password"
            placeholder="كلمة السر"
            className="w-full mx-auto border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={25}
              className="cursor-pointer"
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={25}
              className=" cursor-pointer"
            />
          )}
        </div>
        {loading ? <Loading /> : ""}
        <div className="text-red-600">{error}</div>
        <div className="flex gap-2 items-center justify-end">
          <label htmlFor="role" className="">
            مدير قسم
          </label>
          <input
            id="role"
            type="checkbox"
            className="w-fit"
            onChange={handleChangeRole}
            checked={isSectionAdmin}
          />
        </div>
        <div className="w-fit mx-auto">
          <div
            onClick={handleSubmitMain}
            className="py-3 px-14 bg-Green text-white cursor-pointer rounded-xl"
          >
            إرسال
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
