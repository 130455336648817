import React, { useEffect, useState } from "react";
import { Add, Loading, Table, Title } from "../../components";
import { useFETCH, useFilter, usePOST } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";

const CostCenters = () => {
  const { filter, setFilter } = useFilter();
  const [typingTimeout, setTypingTimeout] = useState("");
  const [temp, setTemp] = useState(0);
  const is_archived = filter.get("is_archived");

  const { data, deleteItem, isLoading, successfulDelete } = useFETCH(
    `admin/cost-centers${
      filter.get("page")
        ? "?page=" + filter.get("page") + `&temp=${temp}`
        : `?tmep=${temp}`
    }${filter.get("search") ? "&search=" + filter.get("search") : ""}${
      filter.get("is_archived")
        ? "&is_archived=" + filter.get("is_archived")
        : ""
    }`,
    "admin/cost-centers"
  );

  useEffect(() => {
    if (successfulDelete) setTemp((prev) => prev + 1);
  }, [successfulDelete]);

  const { printClick } = useFETCH(`admin/cost-centers`);
  const { handleSubmit } = usePOST({});
  return (
    <div>
      <Row>
        <Col className="" md={4}>
          <input
            type="search"
            placeholder={"إبحث"}
            onChange={(e) => {
              const { value } = e.target;
              clearTimeout(typingTimeout);
              const newTypingTimeout = setTimeout(() => {
                setFilter({
                  page: filter.get("page") ? filter.get("page") : 1,
                  search: value,
                });
              }, 1000);
              setTypingTimeout(newTypingTimeout);
            }}
            className={"w-full border border-black/50 py-2 px-3 rounded-lg"}
          />
        </Col>
        <Col className="" md={4}>
          <button
            onClick={() =>
              setFilter({
                is_archived: 1,
                page: filter.get("page") ? filter.get("page") : 1,
                search: filter.get("search") ? filter.get("search") : "",
              })
            }
            className={` ${
              is_archived !== null
                ? +is_archived === 1
                  ? "!bg-Green"
                  : ""
                : "!bg-Green"
            } w-full bg-gray-400 py-3 rounded-2xl text-white`}
          >
            المؤرشفة
          </button>
        </Col>
        <Col className="" md={4}>
          <button
            onClick={() =>
              setFilter({
                is_archived: 0,
                page: filter.get("page") ? filter.get("page") : 1,
                search: filter.get("search") ? filter.get("search") : "",
              })
            }
            className={` ${
              is_archived !== null
                ? +is_archived === 0
                  ? "!bg-Green"
                  : ""
                : "!bg-Green"
            } w-full bg-gray-400 py-3 rounded-2xl  text-white`}
          >
            غير مؤرشفة
          </button>
        </Col>
      </Row>
      <Title title={` مراكز التكلفة`} />
      {isLoading ? <Loading /> : ""}
      <Table
        idDel={deleteItem}
        archive={"admin/cost-centers"}
        archiveFun={handleSubmit}
        print={printClick}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        thead={["اسم المشروع ", "رقم العقد ", "قيمة العقد", "الاقسام"]}
        tbody={[
          "project_name",
          "contract_number",
          "contract_value",
          "sections",
        ]}
        view="cost-centers"
        tdata={data?.data.data.data}
        links={[
          { name: "عرض العمال ", link: "/cost-centers/workers/" },
          { name: "عرض التقارير ", link: "/cost-centers/reports/" },
          { name: "تعديل", link: "/cost-centers/" },
        ]}
      />
      <Add link={`/cost-centers/add`} />
    </div>
  );
};

export default CostCenters;
