import { useEffect } from "react";
import { usePOST } from "../APIs/useMyAPI";
import Loading from "./Loading/Loading";
import { BiLoader } from "react-icons/bi";

const PopUpConfirm = ({ setVisible, formData, setTemp }) => {
  const { handleSubmit, successfulPost, loading } = usePOST(formData);

  useEffect(() => {
    if (successfulPost) {
      setVisible([{}, false]);
      setTemp((prev) => prev + 1);
    }
  }, [successfulPost]);

  return (
    <div className="flex justify-center items-center h-full w-full absolute top-0 left-0">
      <div
        onClick={() => setVisible([{}, false])}
        className="absolute w-full h-full bg-black/30 z-30"
      />
      <div className="bg-[#fff] flex flex-col gap-5 rounded-xl p-5 z-40 w-1/2 min-h-1/3">
        <h1 className="text-2xl font-bold">التأكيد</h1>
        <p className="text-lg font-semibold">
          هل أنت متأكد من إضافة الساعات للعمال المحددين؟
        </p>
        <div className="flex justify-between gap-3">
          <button
            onClick={() => {
              handleSubmit(`s-admin/section/workers/bulk/hours`);
            }}
            className="rounded-lg bg-Green hover:opacity-80 text-white text-lg font-semibold px-6 py-2 text-center"
          >
            {loading ? <BiLoader className="animate-spin" /> : "إضافة"}
          </button>
          <button
            onClick={() => setVisible([{}, false])}
            className="rounded-lg bg-gray-500 hover:opacity-80 text-white text-lg font-semibold px-6 py-2 text-center"
          >
            إلغاء
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpConfirm;
