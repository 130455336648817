import React, { useEffect } from "react";
import { Col, Container, Row } from "../../Grid-system";
import { Back, Loading } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import usePreventWheel from "../../hooks/usePreventWheel";

const AddWorkers = () => {
  const navigate = useNavigate();
  usePreventWheel();
  const { id } = useParams();
  const { data: sectionData } = useFETCH("admin/sections?paginate=1");
  const {
    error,
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loading,
    successfulPost,
  } = usePOST();
  const { data } = useFETCH(`admin/workers/${id}`);
  let dataEdite = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        hour_cost: dataEdite?.hour_cost,
        job_number: dataEdite?.job_number,
        name: dataEdite?.name,
        days: dataEdite?.days,
        week_hours: dataEdite?.week_hours,
        day_hours: dataEdite?.day_hours,
        month_hours: dataEdite?.month_hours,
        section_id: dataEdite?.section?.id,
      });
  }, [dataEdite]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id !== "add" ? `admin/workers/${id}` : "admin/workers");
  };

  useEffect(() => {
    if (successfulPost) navigate("/workers");
  }, [successfulPost]);

  const days = [
    { key: "sunday", display: "الأحد" },
    { key: "monday", display: "الإثنين" },
    { key: "tuesday", display: "الثلاثاء" },
    { key: "wednesday", display: "الأربعاء" },
    { key: "thursday", display: "الخميس" },
    { key: "friday", display: "الجمعة" },
    { key: "saturday", display: "السبت" },
  ];
  const handleChangeDayCheckbox = (event) => {
    const { name, checked } = event.target;
    let newDays =
      formData?.days?.split(",").map((day) => day.trim().toLowerCase()) ?? [];
    if (checked) {
      newDays.push(name);
    } else {
      newDays = newDays.filter((day) => day !== name);
    }
    setFormData({ ...formData, days: newDays.join(",") });
  };
  // console.log(formData?.days?.split(",").includes("thursday"));
  return (
    <div>
      {loading ? <Loading /> : ""}
      <Container>
        <div className="border border-Green rounded-2xl">
          <Row justify="center" className="p-5">
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="text"
                name="name"
                value={formData?.name}
                placeholder="اسم "
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="text"
                name="hour_cost"
                value={formData?.hour_cost}
                placeholder="تكلفة الساعة الانتاجية"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="text"
                name="job_number"
                value={formData?.job_number}
                placeholder="الرقم الوظيفي"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="number"
                name="day_hours"
                value={formData?.day_hours}
                placeholder="عدد الساعات الواجبة في اليوم"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="number"
                name="week_hours"
                value={formData?.week_hours}
                placeholder="عدد الساعات الواجبة في الأسبوع"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <input
                onChange={handleChangeInput}
                type="number"
                name="month_hours"
                value={formData?.month_hours}
                placeholder="عدد الساعات الواجبة في الشهر"
                className=" border border-black py-3 rounded-xl"
              />
            </Col>
            <Col md={9}>
              <span className="font-semibold">حدد أيام العمل للعامل </span>
              <div className="flex justify-center py-4 flex-wrap gap-4">
                {days.map((day) => (
                  <label className="text-end text-l text-Brown flex items-center justify-start gap-1 space-x-2">
                    {" "}
                    {day.display}
                    <input
                      onChange={handleChangeDayCheckbox}
                      type="checkbox"
                      name={day.key}
                      checked={formData?.days?.split(",").includes(day.key)}
                      className="relative w-5 h-5"
                      data-set={formData?.days}
                    />
                  </label>
                ))}
              </div>
            </Col>
            <Col md={9}>
              <select
                name="section_id"
                value={formData?.section_id}
                onChange={handleChangeInput}
                defaultValue={"choose"}
                className="w-full outline-none border border-black py-3 rounded-xl"
              >
                <option className="hidden" value="choose">
                  اختر...
                </option>
                {sectionData?.data.data.map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={9}>
              <div className="text-red-600">{error}</div>
              <button
                onClick={handleSubmitMain}
                className="w-full bg-Green py-3 rounded-2xl  text-white hover:opacity-80"
              >
                حفظ
              </button>
            </Col>
          </Row>
        </div>
      </Container>
      <Back />
    </div>
  );
};

export default AddWorkers;
